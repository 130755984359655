<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin :searchState="searchState"></headerMin>
    </div>
    <div class="doctor_content wrap">
      <div class="indexes"> <span @click="$router.push('/')">首页</span>  > 药师咨询</div>
      <!-- 医生选择 -->
      <div  class="selectTab">
        <!-- 列表 -->
        <div @click="GoPharmDetails(v)" class="box" v-for="(v, i) in pharmList" :key="i">
          <div class="sum s" v-if="v.is_duty == 0">未在线</div>
          <div class="sum" v-else-if="v.waiting_num == 0">空闲</div>
          <div class="sum s" v-else>{{ v.waiting_num }} 人排队</div>
          <div class="headerImg">
            <img v-if="v.avatar != null && v.avatar != ''" :src="v.avatar" />
            <img v-else src="@/assets/image/bg1.jpg" alt />
          </div>
          <p class="p1">
            <span>{{ v.realname }}</span> 药师
          </p>
          <div class="bottom">
            <div>
              <img class="fl" src="@/assets/image/Listofpeople_picture_icon@2x.png" alt />
              <div class="fl ffl">
                <p class="txt1">图文咨询</p>
                <p class="txt2">免费</p>
              </div>
            </div>
            <div>
              <img class="fl" src="@/assets/image/Listofpeople_video_icon@2x.png" alt />
              <div class="fl ffl">
                <p class="txt1">视频咨询</p>
                <p class="txt2">免费</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="showPage">
        <el-pagination
          @current-change="currentChanage"
          background
          layout="prev, pager, next"
          :page-size="16"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom.vue";
import { getOnlineList } from "@/request/index";
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  props: [],
  data() {
    return {
      searchState: 0,
      search: "",
      pharmList: [], // 药师列表
      total: 0,
      showPage: false
    };
  },
  created() {
    this.getPagePharmList(0)
  },
  methods: {
    GoPharmDetails(v) {
      this.$router.push({
        path: "/pharmacist/pharmDetails?id=" + v.id + "&uid=" + v.uid + "&waiting_num=" + v.waiting_num
      });
    },
    GoAllGoods() {
      this.$router.replace({ path: "/allGoods" });
    },
    getPagePharmList(page, category_id = 0) {
      getOnlineList({
        cert_type: 3,
        page_size: 16,
        page,
        category_id
      }).then(res => {
        this.pharmList = res.data.data;
        this.total = res.data.total
        this.showPage = res.data.last_page != 1
      });
    },
    currentChanage(page) {
      this.getPagePharmList(page);
    }
  }
};
</script>
<style lang="less" scoped>
@import "./pharmacist";
</style>